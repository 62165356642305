import React from "react";
import "./homeProducts.css";

import ItemCard2 from "../../../utils/ItemCard2/ItemCard2";
import PreOrder from "../../../utils/PreOrder/PreOrder";
import TextComp from "../../../utils/TextComp/TextComp";
import MainBtn from "../../../utils/MainBtn/MainBtn";

import { productsDescArr } from "../../../data/products";

function HomeProducts({ products }) {
  return (
    <div className="homeProducts_container">
      <div className="homeProductsTitle_div d-flex-row">
        <h4 className="homeProductsTitle ">PRODUCTS</h4>
      </div>

      <div className="items_div d-flex-row aling-items-justify-center">
        {products.map((el, index) => (
          <span key={index}>
            <ItemCard2 props={el} />
          </span>
        ))}
      </div>

      <PreOrder />
      <TextComp textArr={productsDescArr} />
      <MainBtn link={"/products"} text={"See more"} />
    </div>
  );
}

export default HomeProducts;
