import React from "react";
import { Link } from "react-router-dom";
import "./itemCard2.css";

function ItemCard({ props }) {
  return (
    <Link
      to={"/products/" + props.id}
      className="itemCard_container d-flex-col aling-items-justify-center"
    >
      <div className="itemCard_img_div">
        {props.img_url[0].url && (
          <img
            src={props.img_url[0].url}
            alt={props.name}
            className="card_img"
          />
        )}
      </div>
    </Link>
  );
}

export default ItemCard;
