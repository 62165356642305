import React from "react";
import "./productsItemCard.css";

function ProductsItemCard({ data }) {
  return (
    <div className="productCard_div">
      {data.img_url[0].url && <img src={data.img_url[0].url} alt={data.name} />}
      <div className="productData_cont">
        <div className="nameABrDesc">
          <div className="productCardEl productName">{data.name}</div>
          <div className="productCardEl productBrDesc">{data.brief_desc}</div>
        </div>
        <div className="productCardEl productData_div">
          <div className="priceData category">{data.cat}</div>
        </div>
      </div>
    </div>
  );
}

export default ProductsItemCard;
