import React from "react";
import "./home.css";
import { newsHome_data, contactInfo, homePageDescArr } from "../../data/data";
import { productsArrN } from "../../data/products";

import NewsHome from "./NewsHome/NewsHome";
import ContactUs from "./ContactUs/ContactUs";
import HomeProducts from "./HomeProducts/HomeProducts";
import TextComp from "../../utils/TextComp/TextComp";

const Home = () => {
  const notInStore = "- not on the store";
  const products = productsArrN.filter((item) => {
    return !item.name.includes(notInStore);
  });

  const productsFive = products.slice(0, 5);
  return (
    <main className="main_section_container d-flex-col">
      <section className="main_section">
        <TextComp textArr={homePageDescArr} />
      </section>
      <section className="products_section">
        <HomeProducts products={productsFive} />
      </section>
      <section className="news_section">
        <div className="newsIntro_div">
          <NewsHome title={"NEWS"} itemData={newsHome_data} />
        </div>
      </section>

      <ContactUs contactInfo={contactInfo} />
    </main>
  );
};

export default Home;
