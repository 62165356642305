import React from "react";
import "./NewsComponent.css";

function NewsComponent({ props }) {
  let news = [props.text[0], props.text[1]];
  let link = props.link;
  let urlText = "";

  if (props.id === 3) {
    urlText = props.text[1];
  }

  return (
    <>
      <div className="newsHome_container d-flex-col aling-items-justify-center">
        <div className="titleData_div d-flex-col">
          <div className="title_div">{props.title} </div>
          <dir className="title_div_date">{props.date}</dir>
        </div>

        <div className="itemCard_img_newsHome_div d-flex-col aling-items-justify-center">
          {props.img_url !== 0 && (
            <img
              src={props.img_url}
              alt={props.name}
              className="card_img_news_home"
            />
          )}
        </div>
        <div className="home_news_text_div d-flex-col aling-items-justify-center">
          <div className="home_news_text_div_p">
            {news[0] && props.id !== 3 ? (
              <p>
                {news[0]}{" "}
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="newsTitleLink"
                >
                  {link}
                </a>
              </p>
            ) : (
              ""
            )}
            {news[1] && props.id !== 3 ? <p>{news[1]}</p> : ""}
            {props.id === 3 && (
              <p>
                {props.text[0]}
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="newsTitleLink"
                >
                  {urlText}
                </a>
                {props.text[2]}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsComponent;
