import React from "react";
import "./footer.css";
import Logo from "../../utils/Logo/Logo";
import { contactInfo, iconLinks } from "../../data/data";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  const email = contactInfo.email;
  const subject = "Your Subject Here";
  const body = "Hello, ";
  return (
    <footer>
      <div>
        <Logo />
      </div>
      <div className="contacts_div">
        <h3 className="title">CONTACT US</h3>
        <div className="contact_data">
          <span className="contact_data_bold">Phone:</span> {contactInfo.phone}
        </div>
        <div className="contact_data">
          <span className="contact_data_bold">Email:</span> {contactInfo.email}
        </div>
        <div className="contact_data">
          <span className="contact_data_bold">Address:</span>{" "}
          {contactInfo.address}
        </div>
      </div>

      <div className="socialMedia_div">
        <ul>
          {iconLinks.map((link, index) => {
            let color_var = link.color;
            let temp_url = link.url;
            let size = "lg";

            if (link.id === "envelope_icon")
              temp_url = `mailto:${email}?subject=${encodeURIComponent(
                subject
              )}&body=${encodeURIComponent(body)}`;

            return (
              <li key={`key1_${index}`}>
                <a href={temp_url} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    icon={link.icon}
                    size={size}
                    id={link.id}
                    style={{ color: color_var }}
                    className={"footer_icons_link"}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
