import img_1_1 from "../images/01-CorallBlueGold/Image_1.JPG";
import img_1_IMG_6972 from "../images/01-CorallBlueGold/IMG_6972.JPG";
import img_1_IMG_6977 from "../images/01-CorallBlueGold/IMG_6977.JPG";
import img_1_IMG_6978 from "../images/01-CorallBlueGold/IMG_6978.JPG";
import img_1_IMG_6993 from "../images/01-CorallBlueGold/IMG_6993.JPG";
import img_4_Image_2 from "../images/04-LeafsBlueRed/Image_2.JPG";
import img_4_IMG_6966 from "../images/04-LeafsBlueRed/IMG_6966.JPG";
import img_4_IMG_6979 from "../images/04-LeafsBlueRed/IMG_6979.JPG";
import img_4_IMG_6980 from "../images/04-LeafsBlueRed/IMG_6980.JPG";
import img_4_IMG_6990 from "../images/04-LeafsBlueRed/IMG_6990.JPG";
import img_6_Image_3 from "../images/06-OrnamentsGreenWhite/Image_3.jpg";
import img_6_IMG_6961 from "../images/06-OrnamentsGreenWhite/IMG_6961.jpg";
import img_6_IMG_6987 from "../images/06-OrnamentsGreenWhite/IMG_6987.jpg";
import img_7_IMG_6969 from "../images/07-ChokerGreenRed/IMG_6969.jpg";
import img_7_IMG_6968 from "../images/07-ChokerGreenRed/IMG_6968.jpg";
import img_7_Image_4 from "../images/07-ChokerGreenRed/Image_4.jpg";
import img_7_IMG_6984 from "../images/07-ChokerGreenRed/IMG_6984.jpg";
import img_7_IMG_6991 from "../images/07-ChokerGreenRed/IMG_6991.jpg";
import img_7_IMG_6992 from "../images/07-ChokerGreenRed/IMG_6992.jpg";
import img_8_IMG_7604 from "../images/08-AnglesGreenRed/IMG_7604.jpg";
import img_8_IMG_7606 from "../images/08-AnglesGreenRed/IMG_7606.jpg";
import img_8_IMG_7602 from "../images/08-AnglesGreenRed/IMG_7602.jpg";
import img_10_IMG_7627 from "../images/10-HeartsRedGold/IMG_7627.jpg";
import img_10_IMG_7628 from "../images/10-HeartsRedGold/IMG_7628.jpg";
import img_10_IMG_7623 from "../images/10-HeartsRedGold/IMG_7623.jpg";
import img_10_IMG_7625 from "../images/10-HeartsRedGold/IMG_7625.jpg";
import img_10_IMG_7626 from "../images/10-HeartsRedGold/IMG_7626.jpg";
import img_11_IMG_8218 from "../images/11-WavesOfElegance/IMG_8218_1000x1500.jpg";

export const productsDescArr = [
  "Discover our exquisite range of beaded jewelry, designed to elevate your style and express individuality. Each piece features unique, meticulously crafted beads in captivating colors and shapes, ensuring a distinctive look that stands out. Lightweight and comfortable, these accessories are perfect for both casual outings and special occasions.",
  "With beautiful combinations that evoke charm and sophistication, they make ideal gifts for loved ones or a delightful treat for yourself. Embrace the unique allure of our creations, which embody love and creativity. Explore our selection today and find the perfect accessory that resonates with your personal style and enhances any ensemble!",
];

export const productsArrShopify = [
  "8912385114367",
  "8911104442623",
  "8915299860735",
  "8912389570815",
  "8915289145599",
  "8915293405439",
  "8915303497983",
];

export const productsArrN = [
  {
    id: 1,
    name: "Charming Hearts",
    cat: "Necklace",
    img_url: [
      {
        id: 1,
        url: img_10_IMG_7627,
        name: "Beaded Necklace Red Gold (Hearts) 1",
      },
      {
        id: 2,
        url: img_10_IMG_7628,
        name: "Beaded Necklace Red Gold (Hearts) 2",
      },
      {
        id: 3,
        url: img_10_IMG_7623,
        name: "Beaded Necklace Red Gold (Hearts) 3",
      },
      {
        id: 4,
        url: img_10_IMG_7625,
        name: "Beaded Necklace Red Gold (Hearts) 4",
      },
      {
        id: 5,
        url: img_10_IMG_7626,
        name: "Beaded Necklace Red Gold (Hearts) 5",
      },
    ],
    url: "https://www.etsy.com/ca/listing/1772979742/beaded-necklace-red-gold?click_key=b10003a123626856f31784b2ad1b316eba0d3217%3A1772979742&click_sum=65dc868b&ref=shop_home_active_6&frs=1",
  },
  {
    id: 2,
    name: "Coral Bloom",
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_1_1, name: "Beaded Necklace Blue Gold 1" },
      { id: 2, url: img_1_IMG_6972, name: "Beaded Necklace Blue Gold 2" },
      { id: 3, url: img_1_IMG_6977, name: "Beaded Necklace Blue Gold 3" },
      { id: 4, url: img_1_IMG_6978, name: "Beaded Necklace Blue Gold 4" },
      { id: 5, url: img_1_IMG_6993, name: "Beaded Necklace Blue Gold 5" },
    ],
    url: "https://www.etsy.com/ca/listing/1768995519/beaded-necklace-blue-gold?click_key=bb26802078587b0aa9d68f579036acdccb01824e%3A1768995519&click_sum=1922412a&ref=shop_home_active_1&frs=1",
  },
  {
    id: 3,
    name: "Leafy Dreams",
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_4_Image_2, name: "Beaded Necklace Blue Red 1" },
      { id: 2, url: img_4_IMG_6966, name: "Beaded Necklace Blue Red 2" },
      { id: 3, url: img_4_IMG_6979, name: "Beaded Necklace Blue Red 3" },
      { id: 4, url: img_4_IMG_6980, name: "Beaded Necklace Blue Red 4" },
      { id: 5, url: img_4_IMG_6990, name: "Beaded Necklace Blue Red 5" },
    ],
    url: "https://www.etsy.com/ca/listing/1754802512/beaded-necklace-blue-red?click_key=16f7c16c66411767dbc5193decca6cf8f8ec500c%3A1754802512&click_sum=ae5f2cc2&ref=shop_home_active_4&frs=1",
  },
  {
    id: 4,
    name: "Royal Lace Choker",
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_7_IMG_6969, name: "Beaded Choker Necklace 1" },
      { id: 2, url: img_7_IMG_6968, name: "Beaded Choker Necklace 2" },
      { id: 3, url: img_7_Image_4, name: "Beaded Choker Necklace 3" },
      { id: 4, url: img_7_IMG_6984, name: "Beaded Choker Necklace 4" },
      { id: 5, url: img_7_IMG_6991, name: "Beaded Choker Necklace 5" },
      { id: 6, url: img_7_IMG_6992, name: "Beaded Choker Necklace 6" },
    ],
    url: "https://www.etsy.com/ca/listing/1754810456/beaded-choker-necklace?click_key=e2baa61c7b00ef18d401859d51a1259ced5466ab%3A1754810456&click_sum=57d45b7a&ref=shop_home_active_3&frs=1",
  },
  {
    id: 5,
    name: "Square Harmony",
    cat: "Necklace",
    img_url: [
      { id: 1, url: img_6_Image_3, name: "Beaded Necklace Green White 1" },
      { id: 2, url: img_6_IMG_6961, name: "Beaded Necklace Green White 2" },
      { id: 3, url: img_6_IMG_6987, name: "Beaded Necklace Green White 3" },
    ],
    url: "https://www.etsy.com/ca/listing/1754805814/beaded-necklace-green-white?click_key=1ef53d7e994b5f9a675c45b9c51b07d26437aa96%3A1754805814&click_sum=1d2ec376&ref=shop_home_active_2&frs=1",
  },
  {
    id: 6,
    name: "Triangular Perfection",
    cat: "Necklace",
    img_url: [
      {
        id: 1,
        url: img_8_IMG_7604,
        name: "Beaded Necklace Green Red (Angles) 1",
      },
      {
        id: 2,
        url: img_8_IMG_7606,
        name: "Beaded Necklace Green Red (Angles) 2",
      },
      {
        id: 3,
        url: img_8_IMG_7602,
        name: "Beaded Necklace Green Red (Angles) 3",
      },
    ],
    url: "https://www.etsy.com/ca/listing/1782089130/beaded-necklace-green-red?click_key=fd96d0517fdc49d90f13a5c4289b3b5e54bfab3f%3A1782089130&click_sum=d7aa329c&ref=shop_home_feat_4&frs=1",
  },
  {
    id: 7,
    name: "Waves of Elegance",
    cat: "Necklace",
    img_url: [{ id: 1, url: img_11_IMG_8218, name: "Waves of Elegance 1" }],
    url: "",
  },
];
