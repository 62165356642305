import React, { useEffect } from "react";
import "./product.css";
import { productsArrN, productsArrShopify } from "../../data/products";

import PreOrder from "../../utils/PreOrder/PreOrder";
import MainBtn from "../../utils/MainBtn/MainBtn";

import { useParams, Link } from "react-router-dom";

function Product() {
  const { id } = useParams();
  let num = Number(id);
  let shopifyId = productsArrShopify[num - 1];
  let temp = productsArrN.filter((item) => item.id === num);
  let product = temp[0];

  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    const scriptId = "shopify-buy-button-script";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.id = scriptId;

      document.head.appendChild(script);

      script.onload = () => {
        initializeShopifyBuyButton();
      };
    } else {
      initializeShopifyBuyButton();
    }

    function initializeShopifyBuyButton() {
      const client = window.ShopifyBuy.buildClient({
        domain: "34unpg-10.myshopify.com",
        storefrontAccessToken: "68917caa9ea59e36f4de5a39b7423dfe",
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        const node = document.getElementById("product-component-1734535922872");
        if (node && !node.hasAttribute("data-shopify-buy-button")) {
          ui.createComponent("product", {
            id: shopifyId,
            node: node,
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            options: {
              product: {
                layout: "horizontal",
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  description: true,
                },
                width: "100%",
                text: {
                  button: "Add to cart",
                },
              },
            },
          });

          node.setAttribute("data-shopify-buy-button", "initialized");
        }
      });
    }

    return () => {
      const scriptElement = document.getElementById(scriptId);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [shopifyId]);

  return (
    <main className="product_section_container">
      <section className="product_section">
        <div className="product_sectionLink">
          <Link to="/products">Back to Products</Link>
        </div>

        <div
          id="product-component-1734535922872"
          className="shopify_product_class"
        ></div>
        <div className="shopify_preorder_class">
          <PreOrder />
        </div>

        <div className="purchaseOptions">
          <div className="purchaseEtsy">
            {product.url.length > 0 && (
              <MainBtn link={product.url} siteNew={"y"} text={"Buy"} />
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Product;
