import React from "react";
import "./NewsHome.css";
import NewsComponent from "./NewsComponent/NewsComponent";

function NewsHome({ title, itemData }) {
  return (
    <div className="newsHomeComponent_div d-flex-col aling-items-justify-center">
      <div className="newsHomeComponentTitle_div d-flex-row">
        <h4 className="newsHomeComponentTitle">{title}</h4>
      </div>
      <div className="items_div_news d-flex-col aling-items-justify-center">
        {itemData.map((el, index) => {
          return (
            <span key={index} className="items_div_news_span">
              <NewsComponent props={el} />
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default NewsHome;
